@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.home-container {
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding: 20px;
}

.hero-section {
    padding: 50px 20px;
    text-align: left;
    color: #333;
}

.hero-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;

}

.hero-title {
    color: #0a53be;
}

.hero-text {
    max-width: 60%;
}

.hero-text h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    font-weight: 700;
}

.hero-text h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: 500;
}

.hero-text p {
    font-size: 1.5rem;
    margin-bottom: 30px;
    font-weight: 300;
    color: grey;
}

.hero-image-container {
    flex: 1;
    max-width: 250px;
    text-align: center;
    perspective: 1000px;
    cursor: pointer;
    margin: 0 auto;
}

.hero-image {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.hero-image-container.flipped .hero-image {
    transform: rotateY(180deg);
}

.hero-image-front,
.hero-image-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid dimgray;
}

.hero-image-front {
    background-color: #fff;
}

.hero-image-front img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.hero-image-back {
    background-color: #1e6f9f;
    color: white;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
    font-size: 2rem;
}

.button-container {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.view-work-btn,
.download-cv-btn {
    display: inline-block;
    padding: 15px 30px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.view-work-btn:hover,
.download-cv-btn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Dark Mode Styles */

body.dark-mode {
    background-color: #1e1e1e;
    color: darkgray;
}

body.dark-mode .hero-section {
    background-color: #1e1e1e;
    color: darkgray;
}

body.dark-mode .hero-content {
    background-color: #1e1e1e;
}

body.dark-mode .hero-image img {
    border: 3px solid darkgray;
}

body.dark-mode .view-work-btn,
body.dark-mode .download-cv-btn {
    background-color: #3498db;
}

body.dark-mode .view-work-btn:hover,
body.dark-mode .download-cv-btn:hover {
    background-color: #1e6f9f;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .hero-content {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }

    .hero-text {
        max-width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }

    .hero-image-container {
        max-width: 200px;
        width: 100%;
        margin-bottom: 20px;
    }

    .button-container {
        flex-direction: column;
        align-items: center;
    }

    .view-work-btn,
    .download-cv-btn {
        width: 100%;
        text-align: center;
        padding: 10px 0;
    }
}

/* Footer Styles */
.footer-section {
    padding: 50px 0;
    text-align: center;
    border-top: 1px solid #ccc;
}

.footer-section.dark-mode {
    background-color: #1e1e1e;
    color: darkgray;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-content.dark-mode {
    background-color: #1e1e1e;
    color: darkgray;
}

.footer-item {
    flex: 1 1 30%;
    margin-bottom: 20px;
}

.footer-item.dark-mode {
    flex: 1 1 30%;
    margin-bottom: 20px;
    color: darkgray;
}

.footer-item h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.footer-item.dark-mode h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: darkgray;
}

.footer-item p {
    font-size: 1.2rem;
    font-weight: 400;
    color: #666;
}

.footer-item.dark-mode p {
    font-size: 1.2rem;
    font-weight: 400;
    color: darkgray;
}

.footer-item:last-child {
    flex-basis: 100%;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .footer-item {
        flex: 1 1 100%;
    }
}
