@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.projects-container {
    padding-top: 2%;
    text-align: center;
}


.project-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.project-item {
    flex: 1 1 calc(45% - 20px);
    max-width: 33%;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.project-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.image-container {
    position: relative;
    overflow: hidden;
}

.image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease;
    z-index: 1;
}

.project-item:hover .image-container::before {
    background-color: rgba(0, 0, 0, 0.5);
}

.project-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    z-index: 2;
}

.project-item:hover .overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.project-name {
    margin: 0;

    font-size: 2.5em;
    font-weight: 800;
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s;
}

.project-item:hover .project-name {
    opacity: 1;
}

.placeholder-image {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
    color: #555;
    font-size: 1.5em;
    border-radius: 8px;
}
