@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

/* Donkere modus */
body.dark-mode {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #1e1e1e; /* Donkere achtergrondkleur */
    color: darkgray; /* Witte tekst */
}


.navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 10%;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
    background: white;
}

.navbar-container.dark-mode {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 10%;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
    background-color: #1e1e1e; /* Donkere achtergrondkleur voor de navbar */
}

.navbar-brand-container {
    flex: 0 0 auto;
}

.navbar-toggler-container {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
}

.navbar {
    flex: 1 1 auto;
    padding: 0;
    background: none;
    border: none;
    font-family: 'Roboto', sans-serif;
}

.navbar-brand {
    font-size: 2rem;
    text-decoration: none;
    color: darkgray;
    font-weight: 700;
}

.navbar-brand.dark-mode {
    font-size: 2rem;
    text-decoration: none;
    font-weight: 700;
    color: darkgray;
}


.navbar-nav {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
}

.nav-item {
    text-align: center;
}

.nav-link {
    text-decoration: none;
    color: dimgray;
    padding: 0.5rem 1rem;
    display: block;
    font-weight: 500;
    transition: color 0.3s ease;
}

.nav-link.dark-mode {
    color: darkgray; /* Lichtere tekstkleur voor de nav-links in donkere modus */
}

.nav-link:hover {
    color: black;
}

.linkedin-container {
    flex: 0 0 auto;
    margin-left: 20px;
}

.btn-linkedin {
    color: #0e76a8;
    font-size: 2rem;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    transition: color 0.5s ease;
}

.btn-linkedin:hover {
    color: #005582;
}

.navbar-toggler {
    border: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}


.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%287, 7, 7, 0.7%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler-icon.open {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%287, 7, 7, 0.7%29' stroke-width='2' stroke-linecap='round' d='M6 6l18 18M6 24L24 6'/%3E%3C/svg%3E");
}

.navbar-toggler-icon.dark-mode {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23f0f0f0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler-icon.open.dark-mode {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23f0f0f0' stroke-width='2' stroke-linecap='round' d='M6 6l18 18M6 24L24 6'/%3E%3C/svg%3E");
}

.content-container {
    transition: margin-top 0.2s ease; /* Voeg een soepele overgang toe voor de margin-top */
}

.content-container.menu-open {
    margin-top: 150px; /* Pas dit aan op basis van de grootte van het menu */
}

.content-container.menu-open.dark-mode {
    margin-top: 150px; /* Pas dit aan op basis van de grootte van het menu */
    color: #1e1e1e;
    background-color: #1e1e1e;
}

@media (max-width: 992px) {

    .navbar-collapse {
        position: absolute;
        top: 30px; /* Schuif het menu naar beneden op kleine schermen */
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        display: none;
    }

    .navbar-collapse.dark-mode {
        background-color: #1e1e1e;
    }

    .collapse.show {
        display: block;
    }

}
