body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
}

.about-page {
    margin: 20px auto;
    max-width: 1200px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
}

.sidebar {
    padding: 20px;
    border-right: 1px solid #ddd;
    background-color: #f8f9fa;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar .btn {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
}

.sidebar .btn.active {
    background-color: #0056b3;
    transform: scale(1.05);
}

.sidebar .btn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.content {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.content h2 {
    margin-top: 0;
}

.content-section {
    margin-bottom: 20px;
}

.content ul {
    list-style-type: none;
    padding: 0;
}

.content ul li {
    background: #e0e0e0;
    padding: 10px 15px;
    margin: 10px 0;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.personal-details ul li strong {
    width: 30%;
    font-weight: 600;
}

.personal-details ul li a {
    text-decoration: none;
    color: #007bff;
}

.stars {
    color: #ffcc00; /* Change to a more visible color in light mode */
    font-size: 1.2rem;
}

/* Dark Mode */
body.dark-mode .about-page {
    background-color: #1e1e1e;
    color: #ccc;
}

.personal-details.dark-mode{
    background-color: #1e1e1e;
    color: #ccc;
}

body.dark-mode .sidebar {
    background-color: #1e1e1e;
    border-right: 1px solid #444;
}

body.dark-mode .sidebar .btn {
    color: #ccc;
    background-color: #3498db;
}

body.dark-mode .sidebar .btn.active {
    background-color: #1e6f9f;
}

body.dark-mode .sidebar .btn:hover {
    background-color: #1e6f9f;
}

body.dark-mode .content {
    background-color: #2e2e2e;
    color: #ccc;
}

body.dark-mode .content ul li {
    background: #444;
    color: #ccc;
}

body.dark-mode .stars {
    color: gold;
}
