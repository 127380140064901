body {
  font-family: 'Roboto', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}


.navbar {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}

.navbar-logo {
  color: #333;
  font-size: 1.5rem;
  text-decoration: none;
  margin-right: auto;
}

.navbar-links {
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex: 1;
  padding: 0;
  margin: 0;
}

.navbar-links li {
  margin: 0 10px;
}

.navbar-links a {
  color: #333;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-links a:hover {
  text-decoration: underline;
}

.content {
  padding-top: 40px; /* ruimte voor de navbar */
}

@media (min-width: 992px) {
  .body {
    overflow: visible; /* Verberg de scrollbar */
  }
}
