body {
    margin: 0;
    padding: 0;
    width: 100%;
}

/* Dark Mode Styles */
body.dark-mode {
    color: #e0e0e0;
}

body.dark-mode .project-details {
    background-color: #2e2e2e;
}

body.dark-mode .project-description {
    color: darkgrey;
}

.project-details {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.project-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main-content {
    flex: 3;
}

.sidebar {
    flex: 1;
    margin-left: 10px;
    height: fit-content;
    padding: 5px;
}

.iframe-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
}

.project-iframe {
    width: 100%;
    height: 600px;
    border: none;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-item {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-image, .project-video {
    width: 100%;
    max-width: 1000px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-description {
    font-size: 1.2em;
    line-height: 1.6;
    text-align: center;
    padding-top: 20px;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
    .project-content {
        flex-direction: column;
    }

    .sidebar {
        margin-left: 0;
        margin-top: 20px;
    }

    .project-iframe {
        height: 700px;
    }

    .project-details {
        padding: 10px;
    }

    .project-name {
        font-size: 1.5em;
    }

    .project-description {
        font-size: 1em;
        padding-top: 10px;
    }

    .project-image, .project-video {
        width: 100%;
        max-width: 100%;
    }

    .content-item {
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .project-iframe {
        height: 600px;
    }

    .project-name {
        font-size: 1.2em;
    }

    .project-description {
        font-size: 0.9em;
        padding-top: 5px;
    }
}
