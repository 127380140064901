.toggle-button {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.toggle-button:hover {
    transform: scale(1.1);
}

.toggle-button.light {
    color: #f39c12; /* Zon kleur */
}

.toggle-button.dark {
    color: #3498db; /* Maan kleur */
}
