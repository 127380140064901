.skills-list {
    text-align: left;
    padding: 10px;
    border-radius: 8px;
}

.skills-list h3 {
    text-align: center;
    font-size: 1.55em;
}

.skills-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.skills-list li {
    margin: 4px;
    padding: 12px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
}

.skills-list li:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.skill-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;
}
