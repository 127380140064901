.form-group label {
    font-weight: bold; /* Vetgedrukte labels voor de formulier velden */
}

.card-header{
    background-color: #1e6f9f;
}

.button{
    color: whitesmoke;
    margin-top: 10px;
    background-color: #1e6f9f;
}

body .card-header{
    color: whitesmoke;
}

body.dark-mode .card-header {
    color: whitesmoke;
}

body.dark-mode .card-page  {
    background-color: dimgray;
    color: whitesmoke;
}

body.dark-mode .form-control  {
    background-color: darkgray;
}
